import { ethers } from 'ethers'
import auth_contact from '@/constants/abi/auth.json'

const authcontract = {
  namespaced: true,
  state: () => ({
    auth_contract: null,
    is_whitelisted: null,
    error: null
  }),
  getters: {
    auth_contract: (state) => state.auth_contract,
    is_whitelisted: (state) => state.is_whitelisted,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, auth_contract) {
      state.auth_contract = auth_contract
    },
    initError (state, error) {
      state.error = error
    },
    setIsWhitelisted (state, is_whitelisted) {
      state.is_whitelisted = is_whitelisted
    }
  },
  actions: {
    async init ({ commit, dispatch }) {
      try {
        const signer = this.getters['ethereum/signer']
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const network_id = this.getters['ethereum/network_id']
        const index_network = auth_contact.network.findIndex(network => network.network_id == network_id);
        if (index_network < 0) {
          throw new Error(`Network not supported`);
        }

        const address = auth_contact.network[index_network].address;
        const contract = new ethers.Contract(address, auth_contact.abi, signer)

        commit('initSuccess', contract)
        commit('initError', '')

        try {
          await dispatch('getWhitelistStatus', { address: signer.address, auth_contact: contract })
        } catch (errAddress) {
          errAddress.message
        }
      } catch (err) {
        commit('initError', err.message)
      }
    },
    async getWhitelistStatus ({ commit }, payload) {
      try {
        let { auth_contact, address } = payload
        if (!auth_contact) auth_contact = this.getters['authcontract/auth_contract']
        if (!address) address = this.getters['ethereum/address']

        const is_whitelisted = await auth_contact.isSniperWhitelisted(
          address
        )

        commit('setIsWhitelisted', is_whitelisted)
        return Promise.resolve(is_whitelisted);
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default authcontract
