<template>
  <div class="add-recipient-address">
    <pre v-if="error && error != ''">{{ error }}</pre>
    <b-form @submit.prevent="">
      <b-form-group
        class="mt-2"
        id="address-field"
        label="Recipient addresses"
        label-for="new_address"
        :state="isValidAddress"
      >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <span class="muted-text" v-b-tooltip.hover.html="inputInfo">
              <b-icon icon="info-circle-fill" variant="info"></b-icon>
            </span>
          </b-input-group-prepend>
          <b-form-input
            v-model="recipient_addresses"
            id="recipient_addresses"
            type="text"
            placeholder="Recipient addresses"
          >
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="is_recipient_contract"
        />
        <label class="form-check-label"> Is recipient contract? </label>
      </div>

      <b-button class="mt-2" variant="primary" @click="addRecipientAddress">
        Add Recipient Address
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stringToArray } from '@/helpers/common'
import { isAddress } from '@/helpers/addressUtils'

export default {
  name: 'AddRecipientAddress',
  data() {
    return {
      is_recipient_contract: false,
      recipient_addresses: '',
      warning: ''
    }
  },
  computed: {
    ...mapGetters('addrecipientaddress', {
      error: 'error',
    }),
    isValidAddress() {
      if (this.recipient_addresses && this.recipient_addresses != '') {
        const recipient_parsed = Array.isArray(this.recipient_addresses) ? this.recipient_addresses : stringToArray(this.recipient_addresses)
        for (const recipient of recipient_parsed) {
          if (!isAddress(recipient)) return false
        }

        return true
      }

      return false
    }
  },
  async mounted() {
    await this.$store.dispatch('snipercontract/init')
  },
  methods: {
    inputInfo() {
      return `
        If you want add address more than one at once, please use [] format!
        Example:
          ["0xe9e7cea3dedca5984780bafc599bd69add087d56","0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"]
          0xe9e7cea3dedca5984780bafc599bd69add087d56,0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c
      `
    },
    async addRecipientAddress () {
      try {
        if (!this.recipient_addresses || this.recipient_addresses == '') {
          alert('Please input address you want to register as recipient in contract');
        }

        const recipient_parsed = Array.isArray(this.recipient_addresses) ? this.recipient_addresses : stringToArray(this.recipient_addresses)

        await this.$store.dispatch('addrecipientaddress/addRecipientAddress', {
          recipient_addresses: recipient_parsed,
          is_recipient_contract: this.is_recipient_contract
        })
      } catch (err) {
        err.message
      }
    },
  },
}
</script>
