import { ethers } from 'ethers'
import erc20_contact from '@/constants/abi/erc-20.json'

const erc20contract = {
  namespaced: true,
  state: () => ({
    erc20_contract: null,
    token_name: '',
    token_decimal: '',
    error: null
  }),
  getters: {
    erc20_contract: (state) => state.erc20_contract,
    token_name: (state) => state.token_name,
    token_decimal: (state) => state.token_decimal,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, erc20_contract) {
      state.erc20_contract = erc20_contract
    },
    initError (state, error) {
      state.error = error
    },
    setTokenName(state, token_name) {
      state.token_name = token_name
    },
    setTokenDecimal(state, token_decimal) {
      state.token_decimal = token_decimal
    }
  },
  actions: {
    async init ({ commit, dispatch }, payload) {
      try {
        const signer = this.getters['ethereum/signer']
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const { token_address } = payload
        const contract = new ethers.Contract(token_address, erc20_contact, signer)

        commit('initSuccess', contract)
        commit('initError', '')

        const token_name = await dispatch('getTokenName')
        const token_decimal = await dispatch('getTokenDecimal')

        return Promise.resolve({
          token_contract: contract,
          token_name,
          token_decimal
        })
      } catch (err) {
        commit('initError', err.message)
        return Promise.reject(err)
      }
    },
    async getTokenName({ commit }) {
      try {
        const erc20_contact = this.getters['erc20contract/erc20_contract']
        const token_name = await erc20_contact.name()

        commit('setTokenName', token_name)

        return Promise.resolve(token_name)
      } catch (err) {
        commit('setTokenName', '')
        return Promise.reject(err)
      }
    },
    async getTokenDecimal({ commit }) {
      try {
        const erc20_contact = this.getters['erc20contract/erc20_contract']
        const token_decimal = await erc20_contact.decimals()

        commit('setTokenDecimal', token_decimal)

        return Promise.resolve(token_decimal)
      } catch (err) {
        commit('setTokenDecimal', '')
        return Promise.reject(err)
      }
    }
  }
}

export default erc20contract
