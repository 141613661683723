import { ethers } from "ethers"
const sendtoken = {
  namespaced: true,
  state: () => ({
    address_list: [],
    error: null
  }),
  getters: {
    address_list: (state) => state.address_list,
    error: (state) => state.error
  },
  mutations: {
    setAddressList (state, payload) {
      state.address_list = payload
    },
    onError (state, payload) {
      state.error = payload
    }
  },
  actions: {
    async sendRecipientToken ({ commit }, payload) {
      try {
        let {
          sniper_contract,
          signer,
          address_to_send,
          token_address,
          percentage,
          receiver_address
        } = payload

        if (!sniper_contract) {
          sniper_contract = this.getters['snipercontract/sniper_contract']
        }
        if (!signer) {
          signer = this.getters['ethereum/signer']
        }
        if (!receiver_address || receiver_address == '') {
          receiver_address = await signer.getAddress()
        }

        const send_token = await sniper_contract.sendRecipientToken(
          address_to_send,
          token_address,
          percentage,
          receiver_address
        )
        const transaction = await send_token.wait(2)

        return Promise.resolve(transaction)
      } catch (err) {
        if (err.data && err.data.message) {
          err.message = err.data.message
        }
        commit('onError', err.message)
        return Promise.reject(err)
      }
    },
    async getAmountToken({ commit }, payload) {
      try {
        const { recipient_contracts } = payload
        let { token_contract, token_decimal, token_address } = payload

        if (!token_contract) token_contract = this.getters['erc20contract/erc20_contract']
        if (!token_decimal) token_decimal = this.getters['erc20contract/token_decimal']
        if (!token_address) token_address = this.getters['erc20contract/token_address']

        const calculated = []
        await Promise.all(recipient_contracts.map(async(address) => {
          if (typeof address == 'string') {
            address = {
              value: address,
              token: 0,
              ether: 0
            }
          }

          const balance = await token_contract.balanceOf(address.value)
          if (balance.toString() > 0) {
            calculated.push({
              value: address.value,
              ether: 0,
              token: ethers.utils.formatUnits(balance, token_decimal)
            })
          } else {
            calculated.push({
              value: address.value,
              ether: address.ether,
              token: address.token
            })
          }
        }))

        commit('setAddressList', calculated)
        return Promise.resolve(calculated)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default sendtoken
