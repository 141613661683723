<template>
  <div class="sell-token">
    <pre v-if="error && error != ''">{{ error }}</pre>

    <b-form @submit.prevent="">
      <div class="form-group">
        <label for="token-sell">
          Address you want to sell (input token address first!)
        </label>
        <input
          type="text"
          class="form-control"
          id="token-sell"
          v-model="token_address"
          placeholder="Token address you want to sell"
          :state="isValidTokenAddress"
          @change="getTokenDetail"
        />
      </div>
      <div class="card mt-3" v-if="(token_name && token_name != '') && (token_decimal && token_decimal != '')">
        <div class="card-body">
          <div class="card-title">
            <h4 v-if="token_name && token_name != ''">
              {{ token_name }}
              <span v-if="token_decimal && token_decimal != ''">{{
                token_decimal
              }}</span>
            </h4>
          </div>
          <div v-if="address_list && address_list.length > 0">
            <b-form-checkbox-group label="">
              <div
                class="form-check"
                v-for="(recipient, index) in address_list"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :key="index"
                  :value="recipient.value"
                  :id="recipient.value"
                  v-model="address_to_sell"
                />
                <label class="form-check-label" :for="recipient.value">
                  {{ recipient.value }} - {{ recipient.token }} token =
                  {{ recipient.ether }} ether
                </label>
              </div>
            </b-form-checkbox-group>
          </div>
          <div v-if="recipient_contracts.length > 0 && address_list.length < 1">
            <span>
              You don't have any address registered on contract or there is no address which have token balance you enter
            </span>
          </div>
        </div>
      </div>

      <div class="form-check mt-3" v-if="address_list && address_list.length > 0">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="show_estimated_ether"
          id="defaultCheck1"
        />
        <label class="form-check-label" for="defaultCheck1">
          Show estimated ethers from token?
        </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="use_custom_pair_address"
        />
        <label class="form-check-label"> Use custom pair address? </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="use_custom_receiver_address"
        />
        <label class="form-check-label"> Use custom receiver address? </label>
      </div>

      <b-form-group
        class="mt-2"
        id="pair-address-field"
        v-if="use_custom_pair_address"
        label="Pair address"
        label-for="pair-address"
        :state="isValidPairAddress"
      >
        <b-form-input
          v-model="pair_address"
          type="text"
          id="pair-address"
          placeholder="Pair address you want to sell"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        class="mt-2"
        id="address-field"
        v-if="use_custom_receiver_address"
        label="Address"
        label-for="address"
        :state="isValidReceiverAddress"
      >
        <b-form-input
          v-model="receiver_address"
          id="address"
          type="text"
          placeholder="Address to receive the sell result"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        class="mt-2"
        id="percent-field"
        label="Percent %"
        label-for="percent"
        :state="isValidPercentage"
      >
        <b-form-input
          v-model="percentage"
          id="percent"
          type="number"
          placeholder="Percent total token you want to sell"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        class="mt-2"
        id="tax-field"
        label="Tax"
        label-for="tax"
        :state="isValidTax"
      >
        <b-form-input
          id="tax"
          v-model="tax"
          type="number"
          placeholder="Tax sell you tolerant"
        >
        </b-form-input>
      </b-form-group>

      <b-button variant="primary" class="mt-3" @click="sellToken">Sell Token</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isAddress } from "@/helpers/addressUtils";

export default {
  name: "SellToken",
  data() {
    return {
      use_custom_pair_address: false,
      use_custom_receiver_address: false,
      show_estimated_ether: false,
      token_address: "",
      pair_address: "",
      receiver_adrress: "",
      percentage: "",
      tax: "",
      address_to_sell: [],
      warning: "",
      interval: "",
      token_contract: "",
      token_name: "",
      token_decimal: "",
    };
  },
  computed: {
    ...mapGetters("selltoken", {
      address_list: "address_list",
      error: "error",
    }),
    ...mapGetters("routercontract", {
      router_contract: "router_contract",
      router_address: "address",
      weth_address: "weth_address",
    }),
    ...mapGetters("snipercontract", {
      recipient_contracts: "recipient_contracts",
    }),
    ...mapGetters("ethereum", {
      default_receiver: "address",
    }),
    isValidTokenAddress() {
      if (this.token_address && this.token_address != "") {
        return isAddress(this.token_address);
      }

      return false;
    },
    isValidPairAddress() {
      if (this.pair_address && this.pair_address != "") {
        return isAddress(this.pair_address);
      }

      return false;
    },
    isValidReceiverAddress() {
      if (this.receiver_adrress && this.receiver_adrress != "") {
        return isAddress(this.receiver_adrress);
      }

      return false;
    },
    isValidTax() {
      if (this.tax && this.tax != "") {
        return parseInt(this.tax) > 0 && parseInt(this.percentage) <= 100;
      }

      return false;
    },
    isValidPercentage() {
      if (this.percentage && this.percentage != "") {
        return (
          parseInt(this.percentage) > 0 && parseInt(this.percentage) <= 100
        );
      }

      return false;
    },
  },
  async mounted() {
    await this.$store.dispatch("snipercontract/init");
  },
  methods: {
    async sellToken() {
      try {
        let receiver = this.default_receiver;
        if (
          this.use_custom_receiver_address &&
          this.receiver_adrress &&
          this.receiver_adrress != ""
        ) {
          receiver = this.receiver_adrress;
        }
console.log('address to sell', this.address_to_sell)
        await this.$store.dispatch("selltoken/sellRecipientToken", {
          address_to_sell: this.address_to_sell,
          token_address: this.token_address,
          pair_address:
            this.pair_address && this.pair_address != ""
              ? this.pair_address
              : this.weth_address,
          percentage: this.percentage,
          receiver_adrress: receiver,
          tax: this.tax,
        });
      } catch (err) {
        err.message;
      }
    },
    async getTokenDetail() {
      if (this.token_address && this.token_address != "") {
        if (isAddress(this.token_address)) {
          const token_details = await this.$store.dispatch(
            "erc20contract/init",
            {
              token_address: this.token_address,
            }
          );

          this.token_contract = token_details.token_contract;
          this.token_name = token_details.token_name;
          this.token_decimal = token_details.token_decimal;

          await this.formatRecipientList();
        }
      }
    },
    async formatRecipientList() {
      try {
        await this.$store.dispatch("selltoken/getAmountTokenAndEtherWorth", {
          recipient_contracts: this.recipient_contracts,
          show_estimated_ether: this.show_estimated_ether,
          token_contract: this.token_contract,
          token_decimal: this.token_decimal,
          router_contract: this.router_contract,
          weth_address: this.weth_address,
          token_address: this.token_address,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    async show_estimated_ether(new_estimated) {
      if (new_estimated) {
        await this.formatRecipientList();
      }
    },
  },
};
</script>
