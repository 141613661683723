import Vue from 'vue'
import Vuex from 'vuex'

import ethereum from './modules/ethereum'
import snipercontract from './modules/sniper-contract'
import routercontract from './modules/router-contract'
import erc20contract from './modules/erc20-contract'
import createlocker from './modules/create-locker'
import authcontract from './modules/auth-contract'
import subscriptioncontract from './modules/subscription-contract'
import selltoken from './modules/sell-token'
import sendtoken from './modules/send-token'
import sendether from './modules/send-ether'
import changerecipientaddress from './modules/change-recipient-address'
import addrecipientaddress from './modules/add-recipient-address'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    ethereum,
    snipercontract,
    routercontract,
    authcontract,
    erc20contract,
    createlocker,
    selltoken,
    sendtoken,
    sendether,
    subscriptioncontract,
    changerecipientaddress,
    addrecipientaddress
  }
})
