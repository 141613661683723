const addrecipientaddress = {
  namespaced: true,
  state: () => ({
    error: null
  }),
  getters: {
    error: (state) => state.error
  },
  mutations: {
    onError (state, payload) {
      state.error = payload
    }
  },
  actions: {
    async addRecipientAddress ({ commit }, payload) {
      try {
        let {
          sniper_contract,
          signer,
          recipient_addresses,
          is_recipient_contract
        } = payload

        if (!sniper_contract) {
          sniper_contract = this.getters['snipercontract/sniper_contract']
        }
        if (!signer) {
          signer = this.getters['ethereum/signer']
        }

        const change_address = await sniper_contract.addRecipientAddress(
          recipient_addresses,
          is_recipient_contract
        )
        const transaction = await change_address.wait(2)

        return Promise.resolve(transaction)
      } catch (err) {
        if (err.data && err.data.message) {
          err.message = err.data.message
        }
        commit('onError', err.message)
        return Promise.reject(err)
      }
    }
  }
}

export default addrecipientaddress
