<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">Evm Snipe.</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
            <li class="nav-item">
              <router-link class="nav-link active" :to="'/'">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link active" :to="'/manage'">Manage</router-link>
            </li>
          </ul>
          <div class="d-flex">
            <Metamask />
          </div>
        </div>
      </div>
    </nav>
    <b-container class="mt-4">
      <router-view />
    </b-container>
  </div>
</template>

<script>
import Metamask from "@/components/Metamask.vue";

export default {
  name: "App",
  components: {
    Metamask,
  },
  metaInfo: {
    title: 'Evm Snipe',
  },
}
</script>

<style>
.profile-address {
  border-radius: 999px;
  background-color: gray;
  padding: 10px;
  color: white;
  /* margin-left: 10px; */
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
}

#nav {
  /* padding: 30px; */
}

#nav a {
  /* font-weight: bold; */
  /* color: #2c3e50; */
}

#nav a.router-link-exact-active {
  /* color: #42b983; */
}

.modal .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background: transparent;
  border: none;
}
</style>
