<template>
  <div class="create-locker" v-on:submit.prevent="createLocker">
    <b-form>
      <b-form-group>
        <label for="input-amount">Amount <span class="text-danger">*</span></label>
        <b-form-input
          v-model="amount_locker"
          type="number"
          placeholder="Amount locker you want to create"
          :state="isValidAmount"
        >
        </b-form-input>
        <b-form-invalid-feedback :state="isValidAmount">
          {{ error }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button class="mt-2" variant="primary" @click="createLocker">Create locker</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateLocker",
  data() {
    return {
      amount_locker: "",
      warning: "",
    };
  },
  computed: {
    ...mapGetters("createlocker", {
      error: "error",
    }),
    isValidAmount() {
      if (this.amount_locker && this.amount_locker != "") {
        return (
          parseInt(this.amount_locker) > 0 && parseInt(this.amount_locker) < 10
        );
      }

      return false;
    },
  },
  async mounted() {
    await this.$store.dispatch("snipercontract/init");
  },
  methods: {
    async createLocker() {
      try {
        await this.$store.dispatch("createlocker/createLocker", {
          amount_locker: this.amount_locker,
        });
      } catch (err) {
        err.message;
      }
    },
  },
};
</script>
