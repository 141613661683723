const createlocker = {
  namespaced: true,
  state: () => ({
    error: null
  }),
  getters: {
    error: (state) => state.error
  },
  mutations: {
    onError (state, payload) {
      state.error = payload
    }
  },
  actions: {
    async createLocker ({ commit }, payload) {
      try {
        let { sniper_contract, signer, amount_locker } = payload
        if (!sniper_contract) {
          sniper_contract = this.getters['snipercontract/sniper_contract']
        }
        if (!signer) {
          signer = this.getters['ethereum/signer']
        }

        const create_locker = await sniper_contract.createNewLocker(amount_locker)
        const transaction = await create_locker.wait(2)

        return Promise.resolve(transaction)
      } catch (err) {
        if (err.data && err.data.message) {
          err.message = err.data.message
        }
        commit('onError', err.message)
        return Promise.reject(err)
      }
    }
  }
}

export default createlocker
