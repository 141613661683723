import { ethers, utils } from 'ethers'
import providerList from '@/constants/providers/providers.json'

const ethereum = {
  namespaced: true,
  state: () => ({
    provider: null,
    network_id: null,
    network_list: providerList,
    signer: null,
    address: null,
    balance: null,
    error: null
  }),
  getters: {
    provider: (state) => state.provider,
    network_id: (state) => state.network_id,
    network_list: (state) => state.network_list,
    signer: (state) => state.signer,
    address: (state) => state.address,
    balance: (state) => state.balance,
    error: (state) => state.error
  },
  mutations: {
    connectSuccess (state, result) {
      state.provider = result.provider
      state.network_id = result.network_id
      state.signer = result.signer
      state.address = result.address
      state.balance = result.balance
    },
    connectError (state, error) {
      state.error = error
    },
    switchNetwork (state, network_id) {
      state.network_id = network_id
    },
    switchAccount (state, address, signer) {
      state.address = address
      state.signer = signer
    }
  },
  actions: {
    async connect ({ commit, dispatch }, connect) {
      try {
        let result = {}
        let { ethereum } = window
        if (!ethereum) {
          commit('connectError', 'Metamask not installed. Please install metamask first!')
          return
        }

        const provider = new ethers.providers.Web3Provider(ethereum, 'any')
        result.address = await dispatch('isSignIn', provider)

        if (!result.address && connect) {
          result.address = await dispatch('getAccount', provider)
        }

        result.network_id = await dispatch('getNetwork', provider)
        result.signer = provider.getSigner()
        result.provider = provider

        commit('connectSuccess', result)
        commit('connectError', '')
      } catch (err) {
        commit('connectError', err.message)
      }
    },
    async isSignIn ({ commit, dispatch }, provider) {
      try {
        if (!provider) provider = this.getters['ethereum/provider']

        const accounts = await dispatch('getAccount', provider)
        if (!accounts) return Promise.resolve(false)

        return Promise.resolve(accounts)
      } catch (err) {
        commit('connectError', err.message)
        return Promise.reject(err)
      }
    },
    async switchNetwork ({ commit, dispatch }, chainId) {
      try {
        const { ethereum } = window
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: utils.hexlify(parseInt(chainId)) }],
        })

        commit('switchNetwork', await dispatch('getNetwork'))
        return Promise.resolve(true);
      } catch (err) {
        await dispatch('addNetwork', chainId)
        return Promise.reject(err);
      }
    },
    async addNetwork ({ commit, dispatch }, chainId) {
      try {
        const { ethereum } = window
        const index_network = providerList.findIndex(provider => provider.chainId == chainId)
        if (index_network < 0) {
          throw new Error(`Network not supported`)
        }

        const chainSelected = providerList[index_network]
        chainSelected.chainId = ethers.utils.hexlify(parseInt(chainSelected.chainId))

        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [chainSelected]
        })

        commit('switchNetwork', await dispatch('getNetwork'))
        return Promise.resolve(true)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getNetwork ({ commit }, provider) {
      try {
        if (!provider) provider = this.getters['ethereum/provider']
        const { chainId } = await provider.getNetwork()

        return Promise.resolve(chainId)
      } catch (err) {
        commit('connectError', err.message)
        return Promise.reject(err)
      }
    },
    async getAccount ({ commit }, provider) {
      try {
        if (!provider) provider = this.getters['ethereum/provider']

        const accounts = await provider.send('eth_requestAccounts', [])
        if (accounts.length < 1) {
          commit('connectError', 'No account detected')
          return Promise.reject(Error('No account detected'))
        }
        return Promise.resolve(accounts[0])
      } catch (err) {
        commit('connectError', 'User refused to connect')
        return Promise.reject(Error('User refused to connect'))
      }
    },
    onSwitchNetwork ({ commit, dispatch }, payload) {
      try {
        commit('switchNetwork', payload.network_id)
      } catch (err) {
        dispatch('addNetwork')
      }
    },
    onSwitchAccount ({ commit }, payload) {
      try {
        let { provider, address } = payload
        if (!provider) provider = this.getters['ethereum/provider']

        commit('switchAccount', address, provider.getSigner())
      } catch (err) {
        commit('connectError', err.message)
      }
    },
  },
}

export default ethereum
