<template>
  <div class="manage">
    <b-row class="">
      <b-col>
        <b-card title="Whitelist status" style="height: 100%">
          <Whitelist />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Subscription status">
          <Subscription />
        </b-card>
      </b-col>
    </b-row>

    <b-tabs content-class="mt-3 py-3" class="mt-5" fill>
      <b-tab title="Create new locker" active>
        <CreateLocker />
      </b-tab>
      <b-tab title="Sell Token">
        <SellToken />
      </b-tab>
      <b-tab title="Send Token">
        <SendToken />
      </b-tab>
      <b-tab title="Send Ether">
        <SendEther />
      </b-tab>
      <b-tab title="Add Recipient Address">
        <AddRecipientAddress />
      </b-tab>
      <b-tab title="Change Recipient Address">
        <ChangeRecipientAddress />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateLocker from "@/components/CreateLocker.vue";
import Whitelist from "@/components/Whitelist.vue";
import Subscription from "@/components/Subscription.vue";
import SellToken from "@/components/SellToken.vue";
import SendToken from "@/components/SendToken.vue";
import SendEther from "@/components/SendEther.vue";
import ChangeRecipientAddress from "@/components/ChangeRecipientAddress.vue";
import AddRecipientAddress from "@/components/AddRecipientAddress.vue";

export default {
  name: "Manage",
  data() {
    return {
      create_locker: "",
      warning: "",
    };
  },
  components: {
    CreateLocker,
    SellToken,
    SendToken,
    SendEther,
    Whitelist,
    Subscription,
    ChangeRecipientAddress,
    AddRecipientAddress
  },
  computed: {
    ...mapGetters("ethereum", {
      provider: "provider",
      address: "address",
      signer: "signer",
      balance: "balance",
      network_id: "network_id",
    }),
  },
};
</script>
