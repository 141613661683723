<template>
  <div class="subscription">
    <p v-if="error && error != ''">
      {{ error }} <fa-icon icon="fa-solid fa-exclamation-triangle"></fa-icon>
    </p>
    <ul>
      <li v-if="expired_status && expired_status != ''">
        Status:
        <span class="text-muted">{{ expired_status }}</span>
      </li>
      <li v-if="subs_left_status && subs_left_status != ''">
        Valid until:
        <span class="text-muted">{{ subs_left_status }}</span>
      </li>
      <li v-if="token_balance && token_balance != ''">
        Your token license balance:
        <span class="text-muted">{{ token_balance }}</span>
        <span v-if="token_balance && token_balance > 0" style="color: blue">
          (Means you still have 1 time left to use the bot for sniping buy)
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Subscription",
  data() {
    return {
      amount_locker: "",
      warning: "",
    };
  },
  computed: {
    ...mapGetters("subscriptioncontract", {
      is_expired: "is_expired",
      subs_left: "subs_left",
      token_license_balance: "balance",
      error: "error",
    }),
    expired_status() {
      if (this.is_expired != null || this.is_expired != undefined) {
        return this.is_expired
          ? "Expired"
          : "Still alive";
      }

      return "-";
    },
    subs_left_status() {
      if (this.subs_left && this.subs_left != "") {
        return new Date(Date.now() + (this.subs_left * 1000)).toLocaleString();
      }

      return "-";
    },
    token_balance() {
      if (this.token_license_balance && this.token_license_balance != "") {
        return this.token_license_balance;
      }

      return "-";
    },
  },
  async mounted() {
    await this.$store.dispatch("subscriptioncontract/init");
  },
};
</script>
