import { ethers } from "ethers"

const sendether = {
  namespaced: true,
  state: () => ({
    address_list: [],
    error: null
  }),
  getters: {
    address_list: (state) => state.address_list,
    error: (state) => state.error
  },
  mutations: {
    setAddressList (state, payload) {
      state.address_list = payload
    },
    onError (state, payload) {
      state.error = payload
    }
  },
  actions: {
    async sendEther ({ commit }, payload) {
      try {
        let {
          sniper_contract,
          signer,
          address_to_send,
        } = payload

        if (!sniper_contract) {
          sniper_contract = this.getters['snipercontract/sniper_contract']
        }
        if (!signer) {
          signer = this.getters['ethereum/signer']
        }

        const send_token = await sniper_contract.sendRecipientBnb(
          address_to_send,
        )
        const transaction = await send_token.wait(2)

        return Promise.resolve(transaction)
      } catch (err) {
        if (err.data && err.data.message) {
          err.message = err.data.message
        }
        commit('onError', err.message)
        return Promise.reject(err)
      }
    },
    async getAmountEther({ commit }, payload) {
      try {
        const { recipient_contracts } = payload
        let { provider } = payload

        if (!provider) provider = this.getters['ethereum/provider']

        const calculated = []
        await Promise.all(recipient_contracts.map(async(address) => {
          if (typeof address == 'string') {
            address = {
              value: address,
              token: 0,
              ether: 0
            }
          }

          const balance = await provider.getBalance(address.value)
          if (balance.toString() > 0) {
            calculated.push({
              value: address.value,
              ether: ethers.utils.formatUnits(balance, 18),
              token: 0
            })
          } else {
            calculated.push({
              value: address.value,
              ether: address.ether,
              token: address.token
            })
          }
        }))

        commit('setAddressList', calculated)
        return Promise.resolve(calculated)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default sendether
