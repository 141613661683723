<template>
  <div class="whitelist">
    <p v-if="error && error != ''">
      {{ error }} <fa-icon icon="fa-solid fa-exclamation-triangle"></fa-icon>
    </p>
    <ul>
      <li v-if="whitelist_status && whitelist_status != ''">
        {{ whitelist_status }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Whitelist",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("authcontract", {
      is_whitelisted: "is_whitelisted",
      error: "error",
    }),
    whitelist_status() {
      return this.is_whitelisted
        ? "Your address is whitelisted"
        : "Your address is not whitelisted";
    },
  },
  async mounted() {
    await this.$store.dispatch("authcontract/init");
  },
};
</script>
