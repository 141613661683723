import { ethers } from 'ethers'
import sniper_contact from '@/constants/abi/sniper-contract.json'

const snipercontract = {
  namespaced: true,
  state: () => ({
    sniper_contract: null,
    recipient_contracts: [],
    recipient_addresses: [],
    error: null
  }),
  getters: {
    sniper_contract: (state) => state.sniper_contract,
    recipient_contracts: (state) => state.recipient_contracts,
    recipient_addresses: (state) => state.recipient_addresses,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, sniper_contract) {
      state.sniper_contract = sniper_contract
    },
    initError (state, error) {
      state.error = error
    },
    setRecipientAddresses (state, payload) {
      state.recipient_addresses = payload
    },
    setRecipientContract (state, payload) {
      state.recipient_contracts = payload
    },
  },
  actions: {
    async init ({ commit, dispatch }) {
      try {
        const signer = this.getters['ethereum/signer']
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const network_id = this.getters['ethereum/network_id']
        const index_network = sniper_contact.network.findIndex(network => network.network_id == network_id);
        if (index_network < 0) {
          throw new Error(`Network not supported`);
        }

        const address = sniper_contact.network[index_network].address;
        const contract = new ethers.Contract(address, sniper_contact.abi, signer)

        commit('initSuccess', contract)
        commit('initError', '')

        try {
          await dispatch('getAllRecipientAddress', { address: signer.address, sniper_contact: contract })
        } catch (errAddress) {
          errAddress.message
        }
        try {
          await dispatch('getAllRecipientContracts', { address: signer.address, sniper_contact: contract })
        } catch (errContract) {
          errContract.message
        }
      } catch (err) {
        commit('initError', err.message)
      }
    },
    async getAllRecipientContracts ({ commit }, payload) {
      try {
        let { sniper_contract, address } = payload
        if (!sniper_contract) sniper_contract = this.getters['snipercontract/sniper_contract']
        if (!address) address = this.getters['ethereum/address']

        const recipients = await sniper_contract.getAllRecipientContract(
          address
        )

        commit('setRecipientContract', recipients)
        return Promise.resolve(recipients);
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getAllRecipientAddress ({ commit }, payload) {
      try {
        let { sniper_contract, address } = payload
        if (!sniper_contract) sniper_contract = this.getters['snipercontract/sniper_contract']
        if (!address) address = this.getters['ethereum/address']

        const recipients = await sniper_contract.getAllRecipientAddress(
          address
        )

        commit('setRecipientAddresses', recipients)

        return Promise.resolve(recipients);
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default snipercontract
