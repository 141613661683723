<template>
  <div>
    <b-button v-if="network_id === null" class="btn border border-dark border-1" v-b-modal.connect-network-modal variant="transparent" type="button">
      <fa-icon icon="fa-solid fa-wallet" />
      Connect Wallet
    </b-button>
    <b-dropdown v-else right variant="transparent" class="border border-dark border-1 rounded" no-caret>
      <template #button-content>
        <fa-icon icon="fa-solid fa-wallet" />
        {{ address | formatAddress }}
      </template>
      <b-dropdown-item v-b-modal.switch-network-modal>Switch Network</b-dropdown-item>
      <!-- <b-dropdown-item href="#">Disconnect</b-dropdown-item> -->
    </b-dropdown>

    <!-- MODAL -->
    <b-modal id="connect-network-modal" title="Connect Wallet" size="sm" centered hide-footer>
      <div class="d-flex w-100">
        <b-button variant="transparent" class="btn border border-dark border-1 w-100" v-on:click='connect(true)'>Metamask</b-button>
      </div>
    </b-modal>

    <b-modal id="switch-network-modal" title="Select Network" size="sm" centered hide-footer>
      <div class="d-flex w-100 flex-column">
        <b-button
          v-for="(network, key) in network_list"
          variant="transparent"
          class="btn border border-1 w-100 mb-2"
          :key="key"
          :class="[ network.chainId == network_id ? 'border-primary disabled' : 'border-dark' ]"
          @click="switchNetwork(network.chainId)"
        >
          {{ network.chainName }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.selected-list:focus {
  background-color: #0b5ed7;
  color: white;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Metamask",
  computed: {
    ...mapGetters("ethereum", {
      provider: "provider",
      address: "address",
      signer: "signer",
      balance: "balance",
      network_id: "network_id",
      network_list: "network_list",
      error: "error",
    }),
  },
  filters: {
    formatAddress: (address) => {
      return address ? address.slice(0, 4) + "..." + address.slice(-4) : '';
    }
  },
  methods: {
    async connect(params) {
      await this.$store.dispatch("ethereum/connect", params);
      await this.$store.dispatch("snipercontract/init");
      await this.$store.dispatch("routercontract/init");
      await this.$store.dispatch("authcontract/init");
      await this.$store.dispatch("subscriptioncontract/init");
      params && this.$bvModal.hide('connect-network-modal');
    },
    async switchNetwork(networkId) {
      await this.$store.dispatch("ethereum/switchNetwork", networkId);
      window.location.reload();
    },
  },
  async mounted() {
    await this.connect(false)

    window.ethereum.on("accountsChanged", (accounts) => {
      this.$store.dispatch("ethereum/onSwitchAccount", {
        address: accounts[0],
        provider: this.provider,
      })
    })

    window.ethereum.on("chainChanged", async (network_id) => {
      await this.$store.dispatch("ethereum/onSwitchNetwork", { network_id })
      await this.$store.dispatch("snipercontract/init")
      await this.$store.dispatch("routercontract/init")
      await this.$store.dispatch("authcontract/init")
      await this.$store.dispatch("subscriptioncontract/init")
    });
  },
};
</script>
