import { ethers } from 'ethers'
import subscription_contact from '@/constants/abi/token-license.json'

const subscriptioncontract = {
  namespaced: true,
  state: () => ({
    subscription_contact: null,
    is_expired: null,
    subs_left: null,
    balance: null,
    error: null
  }),
  getters: {
    subscription_contact: (state) => state.subscription_contact,
    is_expired: (state) => state.is_expired,
    subs_left: (state) => state.subs_left,
    balance: (state) => state.balance,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, subscription_contact) {
      state.subscription_contact = subscription_contact
    },
    initError (state, error) {
      state.error = error
    },
    setIsExpired (state, is_expired) {
      state.is_expired = is_expired
    },
    setSubscriptionLeft (state, subs_left) {
      state.subs_left = subs_left
    },
    setBalance (state, balance) {
      state.balance = balance
    }
  },
  actions: {
    async init ({ commit, dispatch }) {
      try {
        const signer = this.getters['ethereum/signer']
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const network_id = this.getters['ethereum/network_id']
        const index_network = subscription_contact.network.findIndex(network => network.network_id == network_id);
        if (index_network < 0) {
          throw new Error(`Network not supported`);
        }

        const address = subscription_contact.network[index_network].address;
        const contract = new ethers.Contract(address, subscription_contact.abi, signer)

        commit('initSuccess', contract)
        commit('initError', '')

        try {
          await dispatch('getExpiredStatus', { address: signer.address, subscription_contact: contract })
        } catch (errAddress) {
          errAddress.message
        }
        try {
          await dispatch('getSubscriptionLeft', { address: signer.address, subscription_contact: contract })
        } catch (errAddress) {
          errAddress.message
        }
        try {
          await dispatch('getBalance', { address: signer.address, subscription_contact: contract })
        } catch (errAddress) {
          errAddress.message
        }
      } catch (err) {
        commit('initError', err.message)
      }
    },
    async getExpiredStatus ({ commit }, payload) {
      try {
        let { subscription_contact, address } = payload
        if (!subscription_contact) subscription_contact = this.getters['subscriptioncontract/subscription_contact']
        if (!address) address = this.getters['ethereum/address']

        const is_expired = await subscription_contact.isExpired(
          address
        )

        commit('setIsExpired', is_expired)
        return Promise.resolve(is_expired);
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getSubscriptionLeft ({ commit }, payload) {
      try {
        let { subscription_contact, address } = payload
        if (!subscription_contact) subscription_contact = this.getters['subscriptioncontract/subscription_contact']
        if (!address) address = this.getters['ethereum/address']

        const subs_left = await subscription_contact.getSubLeft(
          address
        )

        commit('setSubscriptionLeft', parseInt(subs_left.toString()))
        return Promise.resolve(subs_left);
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getBalance ({ commit }, payload) {
      try {
        let { subscription_contact, address } = payload
        if (!subscription_contact) subscription_contact = this.getters['subscriptioncontract/subscription_contact']
        if (!address) address = this.getters['ethereum/address']

        let balance = await subscription_contact.balanceOf(
          address
        )

        balance = ethers.utils.formatUnits(balance, 18)

        commit('setBalance', balance)
        return Promise.resolve(balance);
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}

export default subscriptioncontract
