<template>
  <section class="text-center container">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <h1 class="fw-light">EVM Snipe.</h1>
        <p class="lead text-muted">Automation trade bots on the AMM Dex blockchain based on EVM</p>
        <p class="links">
          <a href="#" class="text-decoration-underline">Discord</a>
          <a href="#" class="text-decoration-underline">Twitter</a>
          <a href="#" class="text-decoration-underline">Telegram</a>
        </p>
        <p>©2022 EvmSnipe</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>
.links > * {
  margin: 1em 0 0 1em;
}
section > div {
  min-height: 85vh;
}
</style>
