<template>
  <div class="send-ether">
    <pre v-if="error && error != ''">{{ error }}</pre>

    <b-form @submit.prevent="">
      <b-form-group>
        <b-button variant="info" @click="formatRecipientList">
          Get address list
        </b-button>
      </b-form-group>

      <div class="card mt-3">
        <div class="card-body">
          <div class="card-title">
            <h4>
              Address you want to send
            </h4>
          </div>
          <div v-if="address_list && address_list.length > 0">
            <b-form-checkbox-group label="">
              <div
                class="form-check"
                v-for="(recipient, index) in address_list"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :key="index"
                  :value="recipient.value"
                  :id="recipient.value"
                  v-model="address_to_send"
                />
                <label class="form-check-label" :for="recipient.value">
                  {{ recipient.value }} - {{ recipient.ether }} ether
                </label>
              </div>
            </b-form-checkbox-group>
          </div>
          <div v-if="recipient_contracts.length > 0 && address_list.length < 1">
            <span>
              You don't have address any registered on contract or there is no address which have ether balance
            </span>
          </div>
        </div>
      </div>

      <b-form-group>
        <b-button variant="primary" class="mt-3" @:click="sendEther">Send Ether</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SendEther',
  data() {
    return {
      address_to_send: [],
      warning: ''
    }
  },
  computed: {
    ...mapGetters('sendether', {
      address_list: 'address_list',
      error: 'error',
    }),
    ...mapGetters('snipercontract', {
      recipient_contracts: 'recipient_contracts',
    }),
  },
  async mounted() {
    await this.$store.dispatch('snipercontract/init')
  },
  methods: {
    async sendEther () {
      try {
        await this.$store.dispatch('sendether/sendEther', {
          address_to_send: this.address_to_send,
        })
      } catch (err) {
        err.message
      }
    },
    async formatRecipientList() {
      try {
        await this.$store.dispatch('sendether/getAmountEther', {
          recipient_contracts: this.recipient_contracts,
        })
      } catch (err) {
        console.error(err)
      }
    }
  },
}
</script>
