import { ethers } from 'ethers'
import router_contract from '@/constants/abi/routers.json'

const routercontract = {
  namespaced: true,
  state: () => ({
    router_contract: null,
    address: '',
    weth_address: '',
    error: null
  }),
  getters: {
    router_contract: (state) => state.router_contract,
    address: (state) => state.address,
    weth_address: (state) => state.weth_address,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, payload) {
      state.router_contract = payload.router_contract
      state.address = payload.address
      state.weth_address = payload.weth_address
    },
    initError (state, error) {
      state.error = error
    },
  },
  actions: {
    async init ({ commit }) {
      try {
        const signer = this.getters['ethereum/signer']
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const network_id = this.getters['ethereum/network_id']
        const index_network = router_contract.findIndex(network => network.network_id == network_id);
        if (index_network < 0) {
          throw new Error(`Network not supported`);
        }

        const address = router_contract[index_network].address;
        const contract = new ethers.Contract(address, router_contract[index_network].abi, signer)

        commit('initSuccess', { router_contract: contract, address, weth_address: router_contract[index_network].weth })
        commit('initError', '')
      } catch (err) {
        commit('initError', err.message)
      }
    }
  }
}

export default routercontract
