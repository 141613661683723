<template>
  <div class="change-recipient-address">
    <pre v-if="error && error != ''">{{ error }}</pre>
    <b-form @submit.prevent="">
      <b-form-group>
        <label for="index_address_to_change">Address you want to change <span class="text-danger">*</span></label>
        <b-form-select v-if="is_recipient_contract"
          v-model="index_address_to_change"
        >
          <b-form-select-option v-for="(recipient, index) in recipient_contracts" :key="index" :value="index">
            {{ recipient }}
          </b-form-select-option >
        </b-form-select>

        <b-form-select v-else
          v-model="index_address_to_change"
        >
          <b-form-select-option v-for="(recipient, index) in recipient_addresses" :key="index" :value="index">
            {{ recipient }}
          </b-form-select-option >
        </b-form-select>
      </b-form-group>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="is_recipient_contract"
        />
        <label class="form-check-label"> Is recipient contract? </label>
      </div>

      <b-form-group
        class="mt-2"
        id="address-field"
        label="New Address"
        label-for="new_address"
        :state="isValidAddress"
      >
        <b-form-input
          v-model="new_address"
          id="new_address"
          type="text"
          placeholder="New address"
        >
        </b-form-input>
      </b-form-group>

      <b-button class="mt-2" variant="primary" @click="changeRecipientAddress">
        Change Recipient Address
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAddress } from '@/helpers/addressUtils'

export default {
  name: 'ChangeRecipientAddress',
  data() {
    return {
      is_recipient_contract: false,
      index_address_to_change: '',
      new_address: '',
      warning: ''
    }
  },
  computed: {
    ...mapGetters('changerecipientaddress', {
      error: 'error',
    }),
    ...mapGetters('snipercontract', {
      recipient_contracts: 'recipient_contracts',
      recipient_addresses: 'recipient_addresses'
    }),
    isValidAddress() {
      if (this.new_address && this.new_address != '') {
        return isAddress(this.new_address)
      }

      return false
    },
  },
  async mounted() {
    await this.$store.dispatch('snipercontract/init')
  },
  methods: {
    async changeRecipientAddress () {
      try {
        await this.$store.dispatch('changerecipientaddress/changeRecipientAddress', {
          index_address_to_change: this.index_address_to_change,
          new_address: this.new_address,
          is_recipient_contract: this.is_recipient_contract
        })
      } catch (err) {
        err.message
      }
    },
  },
}
</script>
