<template>
  <div class="send-token">
    <pre v-if="error && error != ''">{{ error }}</pre>

    <b-form @submit.prevent="">
      <div class="form-group">
        <label for="token-sell">
          Address you want to send (input token address first!)
        </label>
        <input
          type="text"
          class="form-control"
          id="token-sell"
          v-model="token_address"
          placeholder="Token address you want to send"
          :state="isValidTokenAddress"
          @change="getTokenDetail"
        />
      </div>

      <div class="card mt-3" v-if="(token_name && token_name != '') && (token_decimal && token_decimal != '')">
        <div class="card-body">
          <div class="card-title">
            <h4 v-if="token_name && token_name != ''">
              {{ token_name }}
              <span v-if="token_decimal && token_decimal != ''">{{
                token_decimal
              }}</span>
            </h4>
          </div>
          <div v-if="address_list && address_list.length > 0">
            <b-form-checkbox-group label="">
              <div
                class="form-check"
                v-for="(recipient, index) in address_list"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :key="index"
                  :value="recipient.value"
                  :id="recipient.value"
                  v-model="address_to_send"
                />
                <label class="form-check-label" :for="recipient.value">
                  {{ recipient.value }} - {{ recipient.token }} token
                </label>
              </div>
            </b-form-checkbox-group>
          </div>
          <div v-if="recipient_contracts.length > 0 && address_list.length < 1">
            <span>
              You don't have address any registered on contract or there is no address which have token balance you enter
            </span>
          </div>
        </div>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="use_custom_receiver_address"
        />
        <label class="form-check-label"> Use custom receiver address? </label>
      </div>

      <b-form-group
        class="mt-2"
        id="address-field"
        v-if="use_custom_receiver_address"
        label="Address"
        label-for="address"
        :state="isValidReceiverAddress"
      >
        <b-form-input
          v-model="receiver_address"
          id="address"
          type="text"
          placeholder="Address to receive the token"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        class="mt-2"
        id="percent-field"
        label="Percent %"
        label-for="percent"
        :state="isValidPercentage"
      >
        <b-form-input
          v-model="percentage"
          id="percent"
          type="number"
          placeholder="Percent total token you want to send"
        >
        </b-form-input>
      </b-form-group>

      <b-button variant="primary" class="mt-3" @click="sendToken">Send Token</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAddress } from '@/helpers/addressUtils'

export default {
  name: 'SendToken',
  data() {
    return {
      use_custom_receiver_address: false,
      receiver_adrress: '',
      percentage: '',
      token_address: '',
      address_to_send: [],
      warning: '',
      token_contract: '',
      token_name: '',
      token_decimal: ''
    }
  },
  computed: {
    ...mapGetters('sendtoken', {
      address_list: 'address_list',
      error: 'error',
    }),
    ...mapGetters('snipercontract', {
      recipient_contracts: 'recipient_contracts',
    }),
    ...mapGetters('ethereum', {
      default_receiver: 'address',
    }),
    isValidTokenAddress() {
      if (this.token_address && this.token_address != '') {
        return isAddress(this.token_address)
      }

      return false
    },
    isValidReceiverAddress() {
      if (this.receiver_adrress && this.receiver_adrress != '') {
        return isAddress(this.receiver_adrress)
      }

      return false
    },
    isValidPercentage() {
      if (this.percentage && this.percentage != '') {
        return parseInt(this.percentage) > 0 && parseInt(this.percentage) <= 100
      }

      return false
    },
  },
  async mounted() {
    await this.$store.dispatch('snipercontract/init')
  },
  methods: {
    async sendToken () {
      try {
        let receiver = this.default_receiver
        if (this.use_custom_receiver_address && this.receiver_adrress && this.receiver_adrress != '') {
          receiver = this.receiver_adrress
        }

        await this.$store.dispatch('sendtoken/sendRecipientToken', {
          address_to_send: this.address_to_send,
          token_address: this.token_address,
          percentage: this.percentage,
          receiver_adrress: receiver
        })
      } catch (err) {
        err.message
      }
    },
    async getTokenDetail() {
      if (this.token_address && this.token_address != '') {
        if (isAddress(this.token_address)) {
          const token_details = await this.$store.dispatch('erc20contract/init', {
            token_address: this.token_address
          })

          this.token_contract = token_details.token_contract
          this.token_name = token_details.token_name
          this.token_decimal = token_details.token_decimal

          await this.formatRecipientList()
        }
      }
    },
    async formatRecipientList() {
      try {
        await this.$store.dispatch('sendtoken/getAmountToken', {
          recipient_contracts: this.recipient_contracts,
          token_contract: this.token_contract,
          token_decimal: this.token_decimal,
          token_address: this.token_address
        })
      } catch (err) {
        console.error(err)
      }
    }
  },
}
</script>
